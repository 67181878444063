import React, { useCallback, useState } from "react";
import { Button, Form, Select, Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import { style } from "../../Styles";
import { updateTransactionPaymentStatus } from "../../redux/transactionSlice";
import { useDispatch } from "react-redux";

const TransactionPaymentStatus = ({
    transactionsId,
    isModalOpen,
    handleCancel,
}) => {
    const { t } = useTranslation();
    const paymentStatusValues = [
        {value:"PAID", label: t("statuses.paid")},
        {value:"NOT_PAID", label: t("statuses.notPaid")},
    ];
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const dispatch = useDispatch();
    const [makingApiCall, setMakingApiCall] = useState(false);

    const onSave = (values) => {
        setMakingApiCall(true);
        dispatch(updateTransactionPaymentStatus(values))
        .unwrap()
        .then(() => {
            message.success(t("transactionPaymentStatus.transactionsUpdatedSuccessfully"));
            setUnsavedChanges(false);
        })
        .catch((e) => {
            message.error(t("transactionPaymentStatus.couldNotUpdateTransactions"));
        })
        .finally(() => {
            handleCancel();
            setMakingApiCall(false);
        });
    }

    const onCancel = (unsavedChanges) =>{
        handleCancel();
    }

    const handleDirty = useCallback(() => {
        setUnsavedChanges(true);
      }, []);

    return (
    <Modal
      title={t("transactionPaymentStatus.title")}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={650}
      //height={600}
      style={{body:{height: "1000 px"}}}
    >
        
    <Form
      name="paymentStatus"
      layout="vertical"
      initialValues={null}
      onFinish={(values) =>
        onSave({
          ...values,
          transactionsId,
        })
      }
      //onFinishFailed={onSaveFailed}
      autoComplete="off"
      onValuesChange={() => handleDirty()}
    >
        <Form.Item
            label={t("transactionPaymentStatus.paymentStatus")}
            name="paymentStatus"
            rules={[
            {
                required: true,
                message: t("transactionPaymentStatus.pleaseSelectPaymentStatus"),
            },
            ]}
            width="500"
        >
        <Select
          size="middle"
          allowClear
          optionLabelProp="label"
        >
          {paymentStatusValues.map((p) => (
            <Select.Option
              key={p.value}
              value={p.value}
              label={
                <span style={style.dataContainer}>
                {p.value == 'PAID' ? (
                  <div style={{...style.statusActif, width: 100, padding: 0}}>{t("statuses.paid")}</div>
                ) : (
                  <div style={{...style.statusInactif, width: 100, padding: 0}}>{t("statuses.notPaid")}</div>
                )}
              </span>
              }
            >
              <span style={style.dataContainer}>
            {p.value == 'PAID' ? (
              <div style={style.statusActif}>{t("statuses.paid")}</div>
            ) : (
              <div style={style.statusInactif}>{t("statuses.notPaid")}</div>
            )}
          </span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item style={{ display: "flex", alignItems: "end", flexDirection: "column" }}>
        <Button
          type="default"
          style={{ marginBottom: 1, marginRight: 8 }}
          onClick={() => onCancel(unsavedChanges)}
        >
          {t("transactionPaymentStatus.cancel")}
        </Button>
        <Button
          type="primary"
          style={{ marginBottom: 1 }}
          htmlType="submit"
          disabled={!unsavedChanges}
        >
          {t("transactionPaymentStatus.update")}
        </Button>
      </Form.Item>
        </Form>
    </Modal>
    )
};


export default TransactionPaymentStatus; 