import React, { useEffect, useState, useCallback } from "react";
import { Spin, Row, Col, Card, Space, Dropdown, DatePicker, Menu } from "antd";
import { LoadingOutlined, UpCircleFilled, DownCircleFilled, FundFilled, UsergroupDeleteOutlined, WifiOutlined, CloseCircleOutlined, CreditCardOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { dashboardData, getScanLocations, getTotalKiosks, getTotalTicketsCardsRevenue } from "../../redux/dashboardSlice";
import TransactionsStatistics from "./TransactionsStatistics";
import { useTranslation } from "react-i18next";
import TicketSVG from "../../icons/ticket.svg";
import { Label } from "bizcharts";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import HeatMapContainer from "./Heatmap";

const today = dayjs().startOf("day");
const tomorrow = dayjs().add(1, "day").startOf("day");

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

const Dashboard = ({ loading = false }) => {
  const { RangePicker } = DatePicker;
  const { totalTicketsCardsRevenueData, transactionByMonth, totalKiosks, scanLocations } = useSelector((state) => state?.dashboard);
  const { t } = useTranslation()
  
  const itemsDropdown = [
    {
      key: "1",
      value: "1W",
      label: t("dashboard.lastWeek")
    },
    {
      key: "2",
      value: "2W",
      label: t("dashboard.last2Week")
    },
    {
      key: "3",
      value: "1M",
      label: t("dashboard.last1Month")
    },
    {
      key: "4",
      value: "3M",
      label: t("dashboard.last3Month")
    },
    {
      key: "5",
      value: "6M",
      label: t("dashboard.last6Month")
    },
    {
      key: "6",
      value: "1Y",
      label: t("dashboard.lastYear")
    },
  ]

  const items = [
    {
      key: "1",
      label: t("dashboard.lastYear")
    },
    {
      key: "2",
      label: t("dashboard.last2Year")
    }
  ]

  const menuItems = itemsDropdown.map((item) => ({
    key: item.key,
    label: item.label,
  }));

  const [makingApiCall, setMakingApiCall] = useState(null)
  const [includePreviousYearValue, setIncludePreviousYearValue] = useState(false)
  const [includePreviousYearLabel, setIncludePreviousYearLabel] = useState(t("dashboard.lastYear"))
  const [includePeriodValue, setIncludePeriodValue] = useState("1W")
  const [includePeriodLabel, setIncludePeriodLabel] = useState(itemsDropdown[0]?.label)
  const [dateRange, setdateRange] = useState([today, tomorrow]);
  const [locations, setLocations] = useState([]);
  const dispatch = useDispatch();
  const defaultMapBounds = {
    southWest: [35.782170703266075, 11.71142578125],
    northEast: [38.41055825094609, 16.512451171875004],
  };

  const fetchDashboardData = (includePreviousYear) => {
    dispatch(dashboardData(includePreviousYear))
      .unwrap()
      .then((data) => {
        setMakingApiCall(true)})
      .catch(() => setMakingApiCall(false));
  }

  const fetchTotalTicketsCardsRevenue = (dateRange) => {
    dispatch(getTotalTicketsCardsRevenue({start:dateRange[0], end: dateRange[1]}))
      .unwrap()
      .then((data) => {
        setMakingApiCall(true)})
      .catch(() => setMakingApiCall(false));
  }

  const fetchTotalKiosks = () => {
    dispatch(getTotalKiosks())
      .unwrap()
      .then((data) => {
        setMakingApiCall(true)})
      .catch(() => setMakingApiCall(false));
  }

  useEffect(() => {
    fetchDashboardData(includePreviousYearValue);
    //fetchTotalTicketsCardsRevenue(dateRange);
    fetchTotalKiosks()
  }, [dispatch]);

  useEffect(() => {
    fetchTotalTicketsCardsRevenue(dateRange);
  }, [dateRange])
  const includePreviousYearAPI = ({key}) => {
    setIncludePreviousYearValue(key == 1 ? false : true);
    setIncludePreviousYearLabel(key == 1 ? t("dashboard.lastYear") : t("dashboard.last2Year"))
    fetchDashboardData(key == 1 ? false : true);
  }

  const updateHeatmapDropdown = useCallback(({ key }) => {
    const selectedItem = itemsDropdown.find((item) => item.key === key);
    if (selectedItem) {
      setIncludePeriodValue(selectedItem.value);
      setIncludePeriodLabel(selectedItem.label);
    }
  },[]);

  

  const fetchScanLocationsData = (includePreviousYear = "1W") => {
    dispatch(getScanLocations(includePreviousYear))
      .unwrap()
      .then((data) => {
        setLocations(data);
        setMakingApiCall(true)})
      .catch(() => setMakingApiCall(false));
  }

  useEffect(() => {
    fetchScanLocationsData(includePeriodValue);
  }, [includePeriodValue])

  const menu = (
    <Menu
      items={itemsDropdown.map((item) => ({
        key: item.key,
        label: item.label,
      }))}
      onClick={updateHeatmapDropdown}
    />
  );



  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
      tip={
        <div>
          <h2>Loading...</h2>
        </div>
      }
    >
      <>
      <Row gutter={12} >
      <Col span={6} >
      <RangePicker
            style={{ marginBottom: "10px", marginRight: "6%", fontSize: 16}}
            defaultValue={dateRange}
            onChange={(date) => setdateRange(date)}
            format={"DD-MM-YYYY"}
            allowClear={false}
            placeholder={[
              t("drawerFilter.startDate"),
              t("drawerFilter.endDate"),
            ]}
          />
    </Col>  
      </Row>
        <Row gutter={12}>
          <Col span={6} >
            <Card
              title={t("dashboard.totalTicketsSold")}
              style={{
                width: 300,
                //padding : "0px"
              }}
              bodyStyle={{padding : "16px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly", alignItems: "center"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalTicketsCardsRevenueData?.totalTicketSold}</p>
                {/* <UpCircleFilled style={{color: "green", fontSize: "25px" }} /> */}
                <img src={TicketSVG} style={{ width: "25px", height: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.totalTravelCardsSold")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "16px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalTicketsCardsRevenueData?.totalCardSold}</p>
                {/* <DownCircleFilled style={{color: "red", fontSize: "25px" }} /> */}
                <CreditCardOutlined style={{fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.totalRevenue")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{display: "flex" , flexDirection: "column"}} >
                <span  style={{fontSize: 16, fontWeight: "bold"}}>€ {totalTicketsCardsRevenueData?.totalRevenue?.totalRevenue}</span>
                <span style={{marginTop: "2px"}}>{t("dashboard.cash")} € {totalTicketsCardsRevenueData?.totalRevenue?.cash} {t("dashboard.card")} € {totalTicketsCardsRevenueData?.totalRevenue?.card} </span>
                </p>
                <FundFilled style={{color: "#1677ff", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.totalDrivers")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "16px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalKiosks?.totalDrivers}</p>
                <UsergroupDeleteOutlined style={{ fontSize: "25px" }}/>
              </div>
            </Card>
          </Col>
        </Row>


        <Row gutter={6} style={{marginTop: "10px", marginBottom: "10px"}}>
        <Col span={12} >
          <Card
              key="transactionCard"
              title={
                <div style={{fontSize: "16px", display:"flex", justifyContent: "space-between"}} >
                {t("dashboard.transactionsByMonth")}
                <Dropdown menu={{ items, onClick : includePreviousYearAPI }} style={{fontSize: 16}}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                    {includePreviousYearLabel}
                    </Space>
                  </a>
                </Dropdown>
                </div>

              }
              style={{
                //width: "50%",
              }}
              bodyStyle={{padding : "16px"}}
            >      
          <TransactionsStatistics data={transactionByMonth?.transactionPerMonth} />
          </Card>
          </Col>
          <Col span={12} >
            <Card
                key="mapCard"
                title={
                  <div style={{fontSize: "16px", display:"flex", justifyContent: "space-between"}} >
                  {t("dashboard.ticketValidationHeatmap")}
                  <Dropdown
                          menu={{
                      items: menuItems,
                      onClick: updateHeatmapDropdown,
                    }}
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()} style={{ fontSize: "16px" }}>
                      <Space>{includePeriodLabel}</Space>
                    </a>
                  </Dropdown>
                  </div>

                }
                style={{
                  //width: "300px",
                  //height: "300px"
                }}
                bodyStyle={{padding : "16px"}}
              >      
              {locations ? <HeatMapContainer defaultMapBounds={defaultMapBounds} data={locations} /> : <span> {t("noData")}</span> }
            </Card>
          </Col>
        </Row>       

        <Row gutter={12}>
          <Col span={6} >
            <Card
              title={t("dashboard.totalKiosks")}
              style={{
                width: "300px",
                //padding : "0px"
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalKiosks?.kioskOnlineOfflineMachine?.onlineMachines}</p>
                <WifiOutlined style={{color: "green", fontSize: "25px" }} />
              </div>
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalKiosks?.kioskOnlineOfflineMachine?.offlineMachines}</p>
                <CloseCircleOutlined style={{color: "red", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.totalPortableKiosks")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalKiosks?.portableKioskOnlineOfflineMachine?.onlineMachines}</p>
                <WifiOutlined style={{color: "green", fontSize: "25px" }} />
              </div>
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalKiosks?.portableKioskOnlineOfflineMachine?.offlineMachines}</p>
                <CloseCircleOutlined style={{color: "red", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.handheldKiosks")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalKiosks?.handheldKioskOnlineOfflineMachine?.onlineMachines}</p>
                <WifiOutlined style={{color: "green", fontSize: "25px" }} />
              </div>
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalKiosks?.handheldKioskOnlineOfflineMachine?.offlineMachines}</p>
                <CloseCircleOutlined style={{color: "red", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.validators")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalKiosks?.validatorOnlineOfflineMachine?.onlineMachines}</p>
                <WifiOutlined style={{color: "green", fontSize: "25px" }} />
              </div>
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{totalKiosks?.validatorOnlineOfflineMachine?.offlineMachines}</p>
                <CloseCircleOutlined style={{color: "red", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
        </Row>
      </>
    </Spin>
  );
};

export default Dashboard;
