import React, { useEffect, useState } from "react";
import { Card, message } from "antd";

import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { useDispatch, useSelector } from "react-redux";
import ImageComponent from "../CommonComponents/ImageComponent/ImageComponent";
import CustomAvatar from "../CommonComponents/CustomAvatar";
import {
  changeUserStatus,
  deleteUser,
  getAllUsersByRole,
} from "../../redux/userSlice";
import { style } from "../../Styles";
import { MinusOutlined } from "@ant-design/icons";
import DrawerUser from "./UserDrawer";
import { getSpanStyleForRole } from "../../Utils/Style/style-depending-on-role";
import { enumToUserFriendlyText } from "../../Utils/Imports/UserFriendlyEnum";
import Header from "../../global/Header/Header";
import ActionDropdownToChangeStatus from "../CommonComponents/ActionDropdownToChangeStatus";
import { useTranslation } from "react-i18next";
import ActionButtonWithChangeStatus from "../CommonComponents/ActionButtonWithChangeStatus";
import ChangePassword from "../Profile/ChangePassword";

const Users = ({ type }) => {
  const { t } = useTranslation();
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

  const columns = [
    {
      title: t("userScreen.table.fullName"),
      key: "fullname",
      render: (record) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {record.profilePicture ? (
              <ImageComponent size={40} imageId={record?.profilePicture} />
            ) : (
              <CustomAvatar
                name={`${record.name} ${record.surname}`}
                type="initials"
              />
            )}
            <span style={{ marginLeft: 8 }}>
              {record?.name ? (
                `${record.name} ${record.surname}`
              ) : (
                <MinusOutlined />
              )}
            </span>
          </div>
        );
      },
      sorter: (a, b) => {
        const nameA = `${a?.name || ""} ${a?.surname || ""}`;
        const nameB = `${b?.name || ""} ${b?.surname || ""}`;
        return nameA.localeCompare(nameB);
      },
      width: 250,
      show: true,
    },
    {
      title: t("userScreen.table.phone"),
      key: "phone",
      render: (record) => {
        return record?.phone ? (
          <span>{`${record.phoneCountryCode} ${record.phone}`}</span>
        ) : (
          <MinusOutlined />
        );
      },

      sorter: (a, b) => {
        const phoneA = `${a?.phoneCountryCode || ""} ${a?.phone || ""}`;
        const phoneB = `${b?.phoneCountryCode || ""} ${b?.phone || ""}`;
        return phoneA?.localeCompare(phoneB);
      },
      show: true,
    },
    {
      title: t("userScreen.table.roles"),
      key: "roles",
      render: (record) => {
        return record?.roles ? (
          record?.roles?.map((role) => (
            <span style={style.dataContainer}>
              <div style={getSpanStyleForRole(role)}>
                {enumToUserFriendlyText(role)}
              </div>
            </span>
          ))
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => {
        const rolesA = a?.roles || [];
        const rolesB = b?.roles || [];
        const lengthCompare = rolesA?.length - rolesB?.length;

        // If the lengths are the same, compare the roles lexicographically
        if (lengthCompare === 0) {
          return rolesA?.join(",").localeCompare(rolesB?.join(","));
        }

        // Otherwise, sort by the length of the roles array
        return lengthCompare;
      },
      show: true,
    },
    {
      title: t("userScreen.table.status"),
      key: "status",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.active ? (
              <div style={style.statusActif}>{t("statuses.active")}</div>
            ) : (
              <div style={style.statusInactif}>{t("statuses.inactive")}</div>
            )}
          </span>
        );
      },
      sorter: (a, b) => {
        const statusA = a?.active;
        const statusB = b?.active;
        return statusA - statusB;
      },
      show: true,
    },
    {
      title: t("userScreen.table.actions"),
      key: "actions",
      render: (record) => {
        return (
          <ActionButtonWithChangeStatus
            record={record}
            onChangeStatus={(record) => onChangeStatus(record)}
            onDelete={() => handleDeleteUser(record.id)}
            recordName={`${record.name} ${record.surname}`}
            onRowClick={(record) => handleSelectedUser(record)}
            displayChangePassword={type?.includes("Technician") || type?.includes("Driver") || type?.includes("Kiosk") || type?.includes("Validator")}
            changePasswordUser={(record) => changePasswordUser(record)}
          />
        );
      },
      show: true,
    },
  ];
  const onChangeStatus = (ObjToSend) => {
    dispatch(changeUserStatus(ObjToSend))
      .unwrap()
      .then(() => {
        message.success(t("userScreen.messages.userStatusUpdatedSuccessfully"));
      })
      .catch(() => {
        message.error("Error changing user status");
      });
  };
  const [filter, setFilter] = useState("");

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isMakingApiCall, setMakingApiCall] = useState(true);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setUser(null);
  };

  const dispatch = useDispatch();

  const UserDependingOnReactComponent = () => {
    switch (type) {
      case "User":
        return {
          addButtonText: t("user.addButtonText"),
          searchPlaceholder: t("user.searchPlaceholder"),
          dispatchAction: getAllUsersByRole({ role: "USER_ROLE" }),
          subTitle: t("user.subTitle"),
          type: "User",
          role: "USER_ROLE",
        };
      case "Admin":
        return {
          addButtonText: t("admin.addButtonText"),
          searchPlaceholder: t("admin.searchPlaceholder"),
          dispatchAction: getAllUsersByRole({ role: "ADMIN_ROLE" }),
          subTitle: t("admin.subTitle"),
          type: "Admin",
          role: "ADMIN_ROLE",
        };
      case "SuperAdmin":
        return {
          addButtonText: t("superAdmin.addButtonText"),
          searchPlaceholder: t("superAdmin.searchPlaceholder"),
          dispatchAction: getAllUsersByRole({ role: "SUPER_ADMIN_ROLE" }),
          subTitle: t("superAdmin.subTitle"),
          type: "SuperAdmin",
          role: "SUPER_ADMIN_ROLE",
        };
      case "Technician":
        return {
          addButtonText: t("technician.addButtonText"),
          searchPlaceholder: t("technician.searchPlaceholder"),
          dispatchAction: getAllUsersByRole({ role: "TECHNICIAN_ROLE" }),
          subTitle: t("technician.subTitle"),
          type: "Technician",
          role: "TECHNICIAN_ROLE",
        };
      case "Driver":
        return {
          addButtonText: t("driver.addButtonText"),
          searchPlaceholder: t("driver.searchPlaceholder"),
          dispatchAction: getAllUsersByRole({ role: "DRIVER_ROLE" }),
          subTitle: t("driver.subTitle"),
          type: "Driver",
          role: "DRIVER_ROLE",
        };
      case "Kiosk":
        return {
          addButtonText: t("kiosk.addButtonText"),
          searchPlaceholder: t("kiosk.searchPlaceholder"),
          dispatchAction: getAllUsersByRole({ role: "KIOSK_ROLE" }),
          subTitle: t("kiosk.subTitle"),
          type: "Kiosk",
          role: "KIOSK_ROLE",
        };
      case "Validator":
        return {
          addButtonText: t("validator.addButtonText"),
          searchPlaceholder: t("validator.searchPlaceholder"),
          dispatchAction: getAllUsersByRole({ role: "VALIDATOR_ROLE" }),
          subTitle: t("validator.subTitle"),
          type: "Validator",
          role: "VALIDATOR_ROLE",
        };
      default:
        return <Users />;
    }
  };

  const { users } = useSelector((state) => state.users);

  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [userToChangePassword, setUserToChangePassword] = useState (null)
  const fetchAll = () => {
    dispatch(UserDependingOnReactComponent().dispatchAction)
      .unwrap()
      .then()
      .catch(() => {
        message.error("Error fetching users");
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };

  useEffect(() => {
    fetchAll();
  }, [dispatch, type]);

  let usersArray = Object.values(users.usersList);
  usersArray = usersArray.map((item) => {
    return { ...item, key: item.id };
  });
  const filtredData = usersArray.filter((item) => {
    return (
      item?.id?.toString().toLowerCase().includes(filter.toLowerCase()) ||
      item?.name?.toString().toLowerCase().includes(filter.toLowerCase()) ||
      item?.phone?.toString().toLowerCase().includes(filter.toLowerCase())
    );
  });

  const handleSelectedUser = (selectedUser) => {
    setUser(selectedUser);
    showDrawer();
  };

  const handleDeleteUser = (id, callback) => {
    setMakingApiCall(true);
    dispatch(
      deleteUser({
        userId: id,
      })
    )
      .unwrap()
      .then(() => {
        message.success("User deleted successfully");
        callback();
      })
      .catch(() => {})
      .finally(() => {
        onClose();
        setMakingApiCall(false);
      });
  };

  const handleGenerateCsv = (event, done) => {
    const promise = async () => {

      const createdCsvData = filtredData.map((item) => {
        return {...item, 
          fullName: item.name +" "+ item.surname, 
          phone: item.phoneCountryCode+ " " + item.phone,
          roles: item.roles?.map((role) => {return enumToUserFriendlyText(role) + " "}),

        }
      })
      setCsvData(createdCsvData)
      setCsvHeaders([
        {
          label: "Full name",
          key: "fullName",
        },
        {
          label: "Phone",
          key: "phone",
        },
        {
          label: "Roles",
          key: "roles",
        },
        {
          label: "Status",
          key: "active",
        },

      ]);
    };

    promise().then(done());
  };

  const changePasswordUser = (record) => {
    setUserToChangePassword(record);
    setOpenChangePassword(true);
    
  };

  return (
    <>
      <Header
        addButtonText={UserDependingOnReactComponent()?.addButtonText}
        onAddButtonClick={() => showDrawer()}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={UserDependingOnReactComponent()?.searchPlaceholder}
        addButtonBackgroundColor={primaryColor} // #ecefff
        addButtonBorderColor={primaryColor}
        addButtonTextColor={"#ffffff"}
        onReload={fetchAll}
        showAddButton={true}
        BreadcrumbItems={[
          {
            title: t("userScreen.header.title"),
          },
          {
            title: UserDependingOnReactComponent()?.subTitle,
          },
        ]}
        showBreadcrumb={true}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={csvData}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Users"}
      />
      <Card style={{ margin: 16, height: "65vh" }}>
        <CustomTableComponents
          isMakingApiCall={users?.loading}
          lo
          columns={columns.filter((column) => column.show)}
          dataArray={filtredData}
          handleDoubleClickRow={(record) => handleSelectedUser(record)}
          handleSelectedRow={() => {}}
        />
      </Card>
      {open && (
        <DrawerUser
          onClose={onClose}
          open={open}
          userId={user?.id}
          type={UserDependingOnReactComponent()?.type}
          role={UserDependingOnReactComponent()?.role}
          translatedType={UserDependingOnReactComponent()?.subTitle}
        />
      )}

      {openChangePassword && (
        <ChangePassword onCloseDrawer={() => setOpenChangePassword(false)} user={userToChangePassword} />
      )}
    </>
  );
};

export default Users;
