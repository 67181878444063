import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { Card, message } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import Header from "../../global/Header/Header";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { getAllKiosks } from "../../redux/kiosksSlice";
import FilteringDrawer from "./Filter/FilteringDrawer";
import {
  getAllMaintenanceLogs,
  getMaintenanceLogsByFilter,
} from "../../redux/maintenanceLogSlice";
import CustomAvatar from "../CommonComponents/CustomAvatar";
import { formatDateAndTime } from "../../Utils/Imports/date-utils";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const today = dayjs().startOf("day");
const tomorrow = dayjs().add(1, "day").startOf("day");

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

const MaintenanceLogs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { maintenanceLogs } = useSelector((state) => state.maintenanceLogs);
  const { kiosks } = useSelector((state) => state.kiosks);

  const columns = [
    {
      title: t("maintenanceLogScreen.table.date"),
      key: "date",
      render: (record) => {
        return record.created ? (
          <span>{formatDateAndTime(record.created)}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      show: true,
    },
    {
      title: t("maintenanceLogScreen.table.kiosk"),
      key: "kiosk",
      render: (record) => {
        const kioskObject = kiosks?.kiosksList?.find((item) => item.id === record.kioskId)
        return <span>{kioskObject?.name}</span>;
      },
      sorter: (a, b) => a.kioskId - b.kioskId,
      show: true,
    },
    {
      title: t("maintenanceLogScreen.table.hardwareId"),
      key: "hardwareId",
      render: (record) => {
        return record.hardwareId ? (
          <span>{record.hardwareId}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.hardwareId - b.hardwareId,
      show: true,
    },
    {
      title: t("maintenanceLogScreen.table.technicien"),
      key: "technician",
      render: (record) => {
        return record.technician ? (
          <div>
            <CustomAvatar
              name={`${record.technician.name} ${record.technician.surname}`}
              type="initials"
            />
            <span>
              {" "}
              {record.technician.name} {record.technician.surname}
            </span>
          </div>
        ) : (
          <MinusOutlined />
        );
      },
      // sorter: (a, b) => a.technician.surname - b.technician.surname,
      show: true,
    },
    {
      title: t("maintenanceLogScreen.table.maintenanceType"),
      key: "maintenanceType",
      render: (record) => {
        return record.maintenanceType ? (
          <span>{record.maintenanceType}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.maintenanceType - b.maintenanceType,
      show: true,
    },
    {
      title: t("maintenanceLogScreen.table.updatedStock"),
      key: "updatedStock",
      render: (record) => {
        return record.updatedStock !== null &&
          record.updatedStock !== undefined ? (
          <span>{record.updatedStock}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.updatedStock - b.updatedStock,
      show: true,
    },
  ];

  const [filter, setFilter] = useState("");
  const [isMakingApiCall, setMakingApiCall] = useState(true);
  const [isFilerDrawerOpen, setisFilerDrawerOpen] = useState(false);
  const [dateRange, setDateRange] = useState([today, tomorrow]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionDropdownFields, setSelectedOptionDropdownFields] =
    useState({
      maintenanceType: null,
      kioskId: null,
    });

  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const openFilterDrawer = () => {
    setisFilerDrawerOpen(true);
  };
  const closeFilterDrawer = () => {
    setisFilerDrawerOpen(false);
  };

  
  const location = useLocation();
  

  //With this object you can pass to filter dropdown list a list of simple values(string or number) or either a list of object
  const dropdownFieldsToShowInFilter = [
    {
      label: t("maintenanceLogScreen.filterDrawer.kiosk"),
      key: "kioskId",
      options: kiosks?.kiosksList || [], // List of objects
      optionLabel: "name",
      optionValue: "id",
    },
    {
      label: t("maintenanceLogScreen.filterDrawer.maintenancetype"),
      key: "maintenanceType",
      options: [
        {id: "CHANGE_ROLL",  value:"CHANGE_ROLL" ,name:t("maintenanceLogScreen.filterDrawer.changeRoll")},
        {id: "TOPUP_CARD", value:"TOPUP_CARD" ,name:t("maintenanceLogScreen.filterDrawer.topupCard")},
        {id: "WITHDRAW_CASH", value:"WITHDRAW_CASH" ,name:t("maintenanceLogScreen.filterDrawer.withdrawCash")},
        {id: "WITHDRAW_COINS", value:"WITHDRAW_COINS" ,name:t("maintenanceLogScreen.filterDrawer.withdrawCoin")},
        {id: "CHECK_ROLE_PAPER", value:"CHECK_ROLE_PAPER" ,name:t("maintenanceLogScreen.filterDrawer.checkRollPaper")},
        {id: "CHECK_CARD_STOCK", value:"CHECK_CARD_STOCK" ,name:t("maintenanceLogScreen.filterDrawer.checkCardStock")},
        {id: "AUTHENTICATION", value:"AUTHENTICATION" ,name:t("maintenanceLogScreen.filterDrawer.authentication")},
        {id: "EMPTY_BIN", value:"EMPTY_BIN" ,name:t("maintenanceLogScreen.filterDrawer.emptyBin")},
        {id: "PRINTER_TEST", value:"PRINTER_TEST" ,name:t("maintenanceLogScreen.filterDrawer.printerTest")},
      ],
      optionLabel: "name",
      optionValue: "id",
    },
    // Add more fields as needed
  ];

  const handleSelectDorpdownsChange = (key, value) => {
    setSelectedOptionDropdownFields((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const fetchLogs = () => {
    setMakingApiCall(true);
    // Set start to 00:00
    const start = dateRange[0]
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Set end to 23:59
    const end = dateRange[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const { filterFromKiosk } = location.state || {};
    let kioskIdFromKiosks;
    let maintenanceTypeFromKiosks;
    if(filterFromKiosk){
      kioskIdFromKiosks = filterFromKiosk.id
      handleSelectDorpdownsChange("kioskId", filterFromKiosk.id)
      if(filterFromKiosk.maintenanceType){
        maintenanceTypeFromKiosks = filterFromKiosk.maintenanceType;
        handleSelectDorpdownsChange("maintenanceType", filterFromKiosk.maintenanceType)
      }
      
    }
    dispatch(
      getMaintenanceLogsByFilter({
        start: start !== undefined ? start : null,
        end: end !== undefined ? end : null,
        deviceId: kioskIdFromKiosks ? kioskIdFromKiosks : null,
        maintenanceType: maintenanceTypeFromKiosks ? maintenanceTypeFromKiosks : null,
      })
    )
      .unwrap()
      .then(() => {
        setMakingApiCall(false)
        closeFilterDrawer();
        location.state = {}
      })
      .catch(() => setMakingApiCall(false));
  };

  const fetchKiosks = async () => {
    setMakingApiCall(true);
    dispatch(getAllKiosks())
      .unwrap()
      .then(() => {})
      .catch(() => {
        message.error("Error while fetching kiosks");
      })
      .finally(() => setMakingApiCall(false));
  };

  useEffect(() => {
    fetchLogs();
  }, [dispatch, dateRange]);

  useEffect(() => {
    fetchKiosks();
  }, [dispatch]);

  const onSaveFilter = () => {
    // Set start to 00:00
    const start = dateRange[0]
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Set end to 23:59
    const end = dateRange[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const payload = {
      start,
      end,
      deviceId: selectedOptionDropdownFields.kioskId,
      maintenanceType: selectedOptionDropdownFields.maintenanceType,
    };
    dispatch(getMaintenanceLogsByFilter(payload))
      .unwrap()
      .then(() => {
        setMakingApiCall(false);
        closeFilterDrawer();
      })
      .catch(() => setMakingApiCall(false));
  };

  const filteredData = Array.isArray(maintenanceLogs?.data)
    ? maintenanceLogs.data.filter((item) => {
        return (
          item?.hardwareId
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          item?.status
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          item?.reason
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          new Date(item.created)
            .toLocaleDateString()
            .toLowerCase()
            .includes(filter.toLowerCase())
        );
      })
    : [];

    const handleGenerateCsv = (event, done) => {
      const promise = async () => {
  
        const createdCsvData = filteredData.map((item) => {
          return {...item, 
            technician: item.technician ? item.technician.name +" "+ item.technician.surname : "", 
            created: formatDateAndTime(item.created),
            kiosks: item?.kioskId ? kiosks?.kiosksList?.find((itemKiosk) => itemKiosk.id === item.kioskId) : ""
          }
        })
        setCsvData(createdCsvData)
        setCsvHeaders([
          {
            label: "Date",
            key: "created",
          },
          {
            label: "Kiosk",
            key: "kiosk",
          },
          {
            label: "Hardware Id",
            key: "hardwareId",
          },
          {
            label: "Technician",
            key: "technician",
          },
          {
            label: "Maintenance Type",
            key: "maintenanceType",
          },
          {
            label: "Updated stock",
            key: "updatedStock",
          },
        ]);
      };
  
      promise().then(done());
    };

    const onFilterByDate = (date, dateString) => {
      const startDate = dayjs(date[0]);
      const endDate = dayjs(date[1]);
      setDateRange([startDate, endDate]);
      closeFilterDrawer();
    };

  return (
    <>
      <Header
        addButtonText={"Add Log"}
        onAddButtonClick={() => {}}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("maintenanceLogScreen.header.search")}
        addButtonTextColor={"#ffffff"}
        onReload={fetchLogs}
        showAddButton={false}
        BreadcrumbItems={[
          {
            title: t("maintenanceLogScreen.header.subtitle"),
          },
          {
            title: t("maintenanceLogScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
        showFilterButton={true}
        onClickFilterButton={() => {
          openFilterDrawer();
        }}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={csvData}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Maintenance-logs"}
        showFilterByDate={true}
        onFilterByDate={(date, dateString) => onFilterByDate(date, dateString)}
        dateRange={dateRange}
      />
      <Card style={{ margin: 16, height: "65vh" }}>
        <CustomTableComponents
          isMakingApiCall={isMakingApiCall}
          columns={columns.filter((column) => column.show)}
          dataArray={filteredData}
          handleDoubleClickRow={() => {}}
          handleSelectedRow={() => {}}
        />

        {isFilerDrawerOpen && (
          <FilteringDrawer
            closeFilteringDrawer={closeFilterDrawer}
            isFilteringDrawerOpened={isFilerDrawerOpen}
            resetFilter={fetchLogs}
            loading={false}
            formName={"Maintenance log filter"}
            title={t("maintenanceLogScreen.header.title")}
            onSave={() => onSaveFilter()}
            type={"Maintenance"}
            showSelect={true}
            selectLabel={t("validatorLogScreen.filterDrawer.kiosks")}
            selectValue={"referenceNumber"}
            selectKey={"id"}
            data={kiosks?.kiosksList || []}
            optionValue={"referenceNumber"}
            displayDateRange={false}
            /* dateRange={dateRange}
            setDateRange={setDateRange} */
            /* selectedOption={selectedOption}
            setSelectedOption={setSelectedOption} */
            displayOneDropdown={false}
            fieldsOfDropdowns={dropdownFieldsToShowInFilter}
            selectedOptionOfDropdownFields={selectedOptionDropdownFields}
            setSelectedOptionOfDropdownFields={handleSelectDorpdownsChange}
          />
        )}
      </Card>
    </>
  );
};

export default MaintenanceLogs;
