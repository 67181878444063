import React, { useEffect, useState } from "react";
import { Button, message, Card } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredTransactions } from "../../redux/transactionSlice";
import TicketCardModal from "./TicketCardModal";
import { formatDate } from "../../Utils/Constants/utils";
import Header from "../../global/Header/Header";
import { formatDateAndTime } from "../../Utils/Imports/date-utils";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { getAllKiosks } from "../../redux/kiosksSlice";
import { MinusOutlined } from "@ant-design/icons";
import { getAllUsersByRole } from "../../redux/userSlice";
import TransactionFilterDrawer from "./TransactionFilter";
import SummaryModal from "./SummaryModal";
import { useLocation } from 'react-router-dom';
import { style } from "../../Styles";
import TransactionPaymentStatus from "./TransactionPaymentStatus";

const today = dayjs().startOf("day");
const tomorrow = dayjs().add(1, "day").startOf("day");

const Transaction = () => {
  const { t } = useTranslation();
  dayjs.extend(isBetween);

  const columns = [
    {
      title: t("transactionScreen.table.kiosk"),
      key: "kiosk",
      render: (record) => {
        return <span>{record.kiosk?.name}</span>;
      },
      sorter: (a, b) => a.kiosk?.name - b.kiosk?.name,
      show: true,
    },
    {
      title: t("transactionScreen.table.transactionType"),
      key: "type",
      render: (record) => {
        return (
          <span>
            {record.type === "TICKET_TYPE" ? (
              <div>{t("transactionScreen.transactionTypes.ticket")}</div>
            ) : record.type === "NEW_CARD_TYPE" ? (
              <div>{t("transactionScreen.transactionTypes.newCard")}</div>
            ) : record.type === "TOP_UP_CARD_TYPE" ?(
              <div>{t("transactionScreen.transactionTypes.topupCard")}</div>
            ) : record.type === "SPECIAL_CARD_TYPE" ?(
              <div>{t("transactionScreen.transactionTypes.specialCard")}</div>
            ) : <div>-</div>}
          </span>
        );
      },
      sorter: (a, b) => a.type.length - b.type.length,
      show: true,
    },
    {
      title: t("transactionScreen.table.paymentType"),
      key: "paymentType",
      render: (record) => {
        return <span>{record.paymentType}</span>;
      },
      sorter: (a, b) => a.paymentType.length - b.paymentType.length,
      show: true,
    },
    {
      title: t("transactionScreen.table.totalAmount"),
      key: "totalAmount",
      render: (record) => {
        return <span>{`€ ${record.totalAmount}`}</span>;
      },
      sorter: (a, b) => a.totalAmount.length - b.totalAmount.length,
      show: true,
    },
    {
      title: t("transactionScreen.table.bundle"),
      key: "bundle",
      render: (record) => {
        return <span>{record.bundle?.name}</span>;
      },
      sorter: (a, b) => a.bundle?.name.length - b.bundle?.name.length,
      show: true,
    },
    {
      title: t("transactionScreen.table.tickets"),
      key: "tickets",
      render: (record) => {
        return record?.tickets ? (
          <Button
            icon={<EyeOutlined />}
            //iconPosition="end"
            onClick={() => showModal(record, "ticket")}
          >
            {`${record.tickets?.length}`}{" "}
            {record.tickets?.length > 1
              ? t("transactionScreen.table.tickets")
              : t("transactionScreen.table.ticket")}
          </Button>
        ) : (
          <div style={{ textAlign: "center" }}> _ </div>
        );
      },
      // sorter: (a, b) => a.name.length - b.name.length,
      show: true,
      width: 150,
    },
    {
      title: t("transactionScreen.table.cards"),
      key: "cards",
      render: (record) => {
        return record?.cards ? (
          <Button
            icon={<EyeOutlined />}
            iconPosition="end"
            onClick={() => showModal(record, "card")}
          >
            {`${record.cards?.length}`}
            {record.cards?.length > 1
              ? t("transactionScreen.table.cards")
              : t("transactionScreen.table.card")}
          </Button>
        ) : (
          <div style={{ textAlign: "center" }}> _ </div>
        );
      },
      //sorter: (a, b) => a.name.length - b.name.length,
      show: true,
      width: 150,
    },
    {
      title: t("transactionScreen.table.date"),
      key: "created",
      render: (record) => {
        return <span>{formatDateAndTime(record.created)}</span>;
      },
      sorter: (a, b) => a.created.length - b.created.length,
      show: true,
      width: 250,
    },
    {
      title: t("transactionScreen.table.driver"),
      key: "driver",
      render: (record) => {
        return (
          <span>
            {record?.driver ? (
              `${record.driver.name} ${record.driver.surname}`
            ) : (
              <MinusOutlined />
            )}
          </span>
        );
      },
      sorter: (a, b) => a.driver?.name - b.driver?.name,
      show: true,
    },
    {
      title: t("transactionScreen.table.paymentStatus"),
      key: "paymentStatus",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.paymentStatus == 'PAID' ? (
              <div style={style.statusActif}>{t("statuses.paid")}</div>
            ) : (
              <div style={style.statusInactif}>{t("statuses.notPaid")}</div>
            )}
          </span>
        );
      },
      sorter: (a, b) => a.paymentStatus - b.paymentStatus,
      show: true,
    },
  ];

  const [filter, setFilter] = useState("");
  const [dateFilter, setDateFilter] = useState([today, tomorrow]);
  const [isMakingApiCall, setMakingApiCall] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalSummary, setModalSummary] = useState(false);

  const [data, setData] = useState([]);
  const [type, setType] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedKiosk, setSelectedKiosk] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [openChangePaymentStatusModal, setOpenChangePaymentStatusModal] = useState(false);

  const onResetFilter = () => {
    setSelectedKiosk(null);
    setSelectedDriver(null);
    setSelectedType(null);
    setSelectedPaymentType(null)
    //setDateFilter([today, tomorrow]);
    // close the drawer
    setSelectedOptionDropdownFields({
      type: null,
      paymentType: null,
      kioskId: null,
      driverId: null,
    })
    setIsDrawerOpen(false);
  };

  const closeFilteringDrawer = () => {
    setIsDrawerOpen(false);
  };

  const onDateRangeChange = (value) => {
    console.log(value)
    setDateFilter(value);
  };
  const [selectedOptionDropdownFields, setSelectedOptionDropdownFields] =
    useState({
      type: null,
      paymentType: null,
      kioskId: null,
      driverId: null,
    });
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  
  const dispatch = useDispatch();
  const location = useLocation();

  const { transactions } = useSelector((state) => state.transactions);
  const { kiosks } = useSelector((state) => state.kiosks);
  const { users } = useSelector((state) => state.users);

  const fetchDrivers = async () => {
    setMakingApiCall(true);
    dispatch(getAllUsersByRole({ role: "DRIVER_ROLE" }))
      .unwrap()
      .then(() => {})
      .catch((err) => {
        message.error("Error while fetching drivers");
      })
      .finally(() => setMakingApiCall(false));
  };

  const fetchKiosks = async () => {
    setMakingApiCall(true);
    dispatch(getAllKiosks())
      .unwrap()
      .then(() => {})
      .catch((err) => {
        message.error("Error while fetching kiosks");
      })
      .finally(() => setMakingApiCall(false));
  };

  useEffect(() => {
    fetchKiosks();
    fetchDrivers();
  }, [dispatch]);

  //With this object you can pass to filter dropdown list a list of simple values(string or number) or either a list of object
  const dropdownFieldsToShowInFilter = [
    {
      label: t("transactionScreen.filterDrawer.kiosks"),
      key: "kioskId",
      options: kiosks?.kiosksList || [], // List of objects
      optionLabel: "name",
      optionValue: "id",
    },
    {
      label: t("transactionScreen.filterDrawer.transactionTypes"),
      key: "type",
      options: [
        {id: "Ticket",  value:"Ticket" ,name:t("transactionScreen.transactionTypes.ticket")},
        {id: "New card", value:"New card" ,name:t("transactionScreen.transactionTypes.newCard")},
        {id: "Top up card", value:"Top up card" ,name:t("transactionScreen.transactionTypes.topupCard")},
        {id: "Special card", value:"Special card" ,name:t("transactionScreen.transactionTypes.specialCard")},
      ],
      optionLabel: "name",
      optionValue: "id",
    },
    {
      label: t("transactionScreen.filterDrawer.paymentTypes"),
      key: "paymentType",
      options: [
        {id: "CASH",  value:"CASH" ,name:t("transactionScreen.paymentTypes.cash")},
        {id: "CARD", value:"CARD" ,name:t("transactionScreen.paymentTypes.card")},
      ],
      optionLabel: "name",
      optionValue: "id",
    },
    {
      label: t("transactionScreen.filterDrawer.drivers"),
      key: "driverId",
      options: users?.usersList || [], // TODO: change the list of users to the list of drivers
      optionLabel: "name",
      optionValue: "id",
    },
    // Add more fields as needed
  ];

  const fetchTransactions = () => {
    setMakingApiCall(true);
    const start = dateFilter[0]
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Set end to 23:59
    const end = dateFilter[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const { filterFromKiosk } = location.state || {};
    if(filterFromKiosk){
      handleSelectDorpdownsChange("type", filterFromKiosk.transactionType)
      handleSelectDorpdownsChange("kioskId", filterFromKiosk?.kiosk)
      location.state = {}
    }
    dispatch(
      getFilteredTransactions({
        start,
        end,
        type: selectedType,
        paymentType: selectedPaymentType,
        kioskId: selectedKiosk,
        driverId: selectedDriver,
      })
    )
      .unwrap()
      .then(() => setMakingApiCall(false))
      .catch((error) => {
        message.error(error.message);
        setMakingApiCall(false);
      });
  };

  useEffect(() => {
    fetchTransactions();
  }, [dispatch, dateFilter, selectedOptionDropdownFields]);

  const filtredData = transactions?.filter((item) => {
    return (
      item?.kiosk?.name?.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1 ||
      item?.paymentType.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1 ||
      item.bundle?.name
        .toString()
        .toLowerCase()
        .indexOf(filter.toLowerCase()) !== -1 ||
      item.type.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
      formatDate(item.created.toString())
        ?.toLowerCase()
        .indexOf(filter.toLowerCase()) !== -1
    );
  });

  const showModal = (record, type) => {
    setData(record);
    setType(type);
    setModalOpen(true);
  };

  const closeModal = () => {
    setData([]);
    setType(null);
    setModalOpen(false);
  };

  const showModalSummary = () => {
    setModalSummary(true);
  };

  const closeModalSummary = () => {
    setModalSummary(false);
  };

  const showModalChangeStatus = () => {
    setOpenChangePaymentStatusModal(true);
  };

  const closeModalChangeStatus = () => {
    setOpenChangePaymentStatusModal(false);
    setSelectedTransaction([]);
  };

  const onFilterByDate = (date, dateString) => {
    const startDate = dayjs(date[0]);
    const endDate = dayjs(date[1]);
    setDateFilter([startDate, endDate]);
  };

  const openFilterDrawer = () => {
    setIsDrawerOpen(true);
  };
  const closeFilterDrawer = () => {
    setIsDrawerOpen(false);
  };

  const onSaveFilter = () => {
    fetchTransactions();
    closeFilterDrawer();
  };

  const handleSelectDorpdownsChange = (key, value) => {
    setSelectedOptionDropdownFields((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleGenerateCsv = (event, done) => {
    const promise = async () => {
      const createdCsvData = filtredData.map((item) => {
        return {
          ...item,
          tickets: item.tickets ? item.tickets.length : 0,
          cards: item.cards ? item.cards.length : 0,
          created: formatDateAndTime(item.created),
        };
      });
      setCsvData(createdCsvData);
      setCsvHeaders([
        {
          label: "Kiosk",
          key: "kiosk.name",
        },
        {
          label: "Transaction type",
          key: "type",
        },
        {
          label: "Payment type",
          key: "paymentType",
        },
        {
          label: "Total amount",
          key: "totalAmount",
        },
        {
          label: "Bundle",
          key: "bundle.name",
        },
        {
          label: "Number of tickets",
          key: "tickets",
        },
        {
          label: "Number of cards",
          key: "cards",
        },
        {
          label: "Date",
          key: "created",
        },
      ]);
    };

    promise().then(done());
  };

  const handleFilter = (values) => {
    onSaveFilter();
  };

  const [selectedTransaction, setSelectedTransaction] = useState([]);

  const onSelectTransactionsChange = (selectedRowKeys) => {
    setSelectedTransaction(selectedRowKeys);
  };

  const rowSelection = {
    type:'checkbox',
    selectedRowKeys: selectedTransaction,
    onChange: onSelectTransactionsChange,
    hideDefaultSelections: true,
    getCheckboxProps: (record) => ({
      //disabled: record.cancelled ? true : false, // Column configuration not to be checked
      name: record.id,
    }),
  };
  

  return (
    <>
      <Header
        onAddButtonClick={() => {}}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("transactionScreen.header.search")}
        addButtonTextColor={"#ffffff"}
        onReload={fetchTransactions}
        showAddButton={false}
        BreadcrumbItems={[
          {
            title: t("transactionScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
        showFilterByDate={true}
        onFilterByDate={(date, dateString) => onFilterByDate(date, dateString)}
        showFilterButton={true}
        onClickFilterButton={() => {
          openFilterDrawer();
        }}
        dateRange={dateFilter}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={csvData}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Transactions"}
        showSummaryButton={true}
        onClickSummaryButton={() => {
          showModalSummary();
        }}
        summaryButtonText={t("transactionSummary.title")}
        showChangeStatusButton={true}
        ChangeStatusButtonText={t("transactionScreen.header.updatePaymentStatus")}
        disableChangeStatusButton={selectedTransaction.length == 0}
        onClickChangeStatusButton={() => {
          showModalChangeStatus();
        }}
      />
      <Card style={{ margin: 16, height: "65vh" }}>
        <CustomTableComponents
          isMakingApiCall={isMakingApiCall}
          columns={columns.filter((column) => column.show)}
          dataArray={filtredData}
          handleSelectedRow={() => {}}
          handleDoubleClickRow={() => {}}
          rowSelection={rowSelection}
        />
        <TicketCardModal
          isModalOpen={isModalOpen}
          handleCancel={closeModal}
          toBeDisplayed={type}
          data={data}
        />
        {isDrawerOpen && (
          <TransactionFilterDrawer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            loading={isMakingApiCall} // TOFIX: loading state
            onSave={handleFilter}
            selectedKiosk={selectedKiosk}
            setSelectedKiosk={setSelectedKiosk}
            selectedDriver={selectedDriver}
            setSelectedDriver={setSelectedDriver}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedPaymentType={selectedPaymentType}
            setSelectedPaymentType={setSelectedPaymentType}
            dateRange={dateFilter}
            onDateRangeChange={onDateRangeChange}
            closeFilteringDrawer={closeFilteringDrawer}
            onResetForm={onResetFilter}
          />
        )}

        {modalSummary && (
          <SummaryModal
            isModalOpen={modalSummary}
            handleCancel={closeModalSummary}
            selectedDate={dateFilter}
            selectedDriver={selectedDriver}
            selectedKiosk={selectedKiosk}
          />
        )}

        {openChangePaymentStatusModal && 
        <TransactionPaymentStatus 
          isModalOpen={openChangePaymentStatusModal}
          handleCancel={closeModalChangeStatus}
          transactionsId={selectedTransaction}
        />
        }
      </Card>
    </>
  );
};

export default Transaction;
