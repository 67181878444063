import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { Button, Card } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import Header from "../../global/Header/Header";
import FilteringDrawer from "./Filter/FilteringDrawer";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import {
  getValidatorLogsByDateRange,
  getValidatorLogsByDateRangeAndDevice,
} from "../../redux/validatorLogSlice";
import { getAllValidators } from "../../redux/validatorSlice";
import { formatDateAndTime } from "../../Utils/Imports/date-utils";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ModalGoogleMaps from "../../global/Modals/ModalGoogleMaps";
const today = dayjs().startOf("day");
const tomorrow = dayjs().add(1, "day").startOf("day");

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

const ValidatorScans = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("validatorLogScreen.table.date"),
      key: "date",
      render: (record) => {
        return record.created ? (
          <span>{formatDateAndTime(record.created)}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      show: true,
    },
    {
      title: t("validatorLogScreen.table.cardNumber"),
      key: "cardNumber",
      render: (record) => {
        return record.cardNumber ? (
          <span>{record.cardNumber}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.cardNumber - b.cardNumber,
      show: true,
    },
    {
      title: t("validatorLogScreen.table.ticketNumber"),
      key: "ticketNumber",
      render: (record) => {
        return record.ticketNumber ? (
          <span>{record.ticketNumber}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.ticketNumber - b.ticketNumber,
      show: true,
    },
    {
      title: t("validatorLogScreen.table.validatorSerialNumber"),
      key: "validatorSerialNumber",
      render: (record) => {
        return record.validatorSerialNumber ? (
          <span>{record.validatorSerialNumber}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.validatorSerialNumber - b.validatorSerialNumber,
      show: true,
    },
    {
      title: t("validatorLogScreen.table.showLocation"),
      key: "showLocation",
      render: (record) => {
        return record?.latitude  && record?.longitude ? (
          <span><Button onClick={() => handleOpenLocation(record) }> {t("validatorLogScreen.table.showLocation")} </Button> </span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.validatorSerialNumber - b.validatorSerialNumber,
      show: true,
    },
  ];

  
  const [isModalLocationOpen, setIsModalLocationOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [filter, setFilter] = useState("");
  const [isMakingApiCall, setMakingApiCall] = useState(true);
  const [isFilerDrawerOpen, setisFilerDrawerOpen] = useState(false);
  const [dateRange, setDateRange] = useState([today, tomorrow]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  
  const handleOpenLocation = (record) => {
    setSelectedLocation(record)
    setIsModalLocationOpen(true);
  };
  const handleCloseLocation = () => {
    setIsModalLocationOpen(false);
  };

  const openFilterDrawer = () => {
    setisFilerDrawerOpen(true);
  };
  const closeFilterDrawer = () => {
    setisFilerDrawerOpen(false);
  };

  const dispatch = useDispatch();
  const { validatorLogs } = useSelector((state) => state.validatorLogs);
  const { validators } = useSelector((state) => state.validators);

  const fetchLogs = () => {
    setMakingApiCall(true);
    // Set start to 00:00
    const start = dateRange[0]
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Set end to 23:59
    const end = dateRange[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    dispatch(
      getValidatorLogsByDateRange({
        start: start !== undefined ? start : null,
        end: end !== undefined ? end : null,
      })
    )
      .unwrap()
      .then(() => setMakingApiCall(false))
      .catch(() => setMakingApiCall(false));
  };

  const fetcValidators = () => {
    setMakingApiCall(true);
    dispatch(getAllValidators())
      .unwrap()
      .then((res) => {
        setMakingApiCall(false);
      })
      .catch(() => setMakingApiCall(false));
  };

  useEffect(() => {
    fetcValidators();
  }, [dispatch]);

  useEffect(() => {
    fetchLogs();
  }, [dispatch, dateRange, selectedOption]);

  const onSaveFilter = () => {
    // Set start to 00:00
    const start = dateRange[0]
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Set end to 23:59
    const end = dateRange[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const payload = {
      start,
      end,
      deviceId: selectedOption,
    };
    dispatch(getValidatorLogsByDateRangeAndDevice(payload))
      .unwrap()
      .then(() => {
        setMakingApiCall(false);
        closeFilterDrawer();
      })
      .catch(() => setMakingApiCall(false));
  };

  const filteredData = Array.isArray(validatorLogs.data)
    ? validatorLogs.data.filter((item) => {
        return (
          item?.card?.cardNumber
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          item?.ticket?.ticketReferenceNumber
            .toString()
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(filter.toLowerCase()) ||
          item?.validator?.serialNumber
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          new Date(item.created)
            .toLocaleDateString()
            .toLowerCase()
            .includes(filter.toLowerCase())
        );
      })
    : [];
    
    const handleGenerateCsv = (event, done) => {
      const promise = async () => {
  
        const createdCsvData = filteredData.map((item) => {
          return {...item, created: formatDateAndTime(item.created)}
        })
        setCsvData(createdCsvData)
        setCsvHeaders([
          {
            label: "Date",
            key: "created",
          },
          {
            label: "Card Number",
            key: "cardNumber",
          },
          {
            label: "Ticket Number",
            key: "ticketNumber",
          },
          {
            label: "Validator Serial Number",
            key: "validatorSerialNumber",
          },
        ]);
      };
  
      promise().then(done());
    };

    const onFilterByDate = (date, dateString) => {
      const startDate = dayjs(date[0]);
      const endDate = dayjs(date[1]);
      setDateRange([startDate, endDate]);
    };

  return (
    <>
      <Header
        onAddButtonClick={() => {}}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("validatorLogScreen.header.search")}
        addButtonTextColor={"#ffffff"}
        onReload={fetchLogs}
        showAddButton={false}
        BreadcrumbItems={[
          {
            title: t("validatorLogScreen.header.subtitle"),
          },
          {
            title: t("validatorLogScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
        showFilterButton={true}
        onClickFilterButton={() => {
          openFilterDrawer();
        }}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={csvData}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Validator-scan"}
        showFilterByDate={true}
        onFilterByDate={(date, dateString) => onFilterByDate(date, dateString)}
        dateRange={dateRange}
      />
      <Card style={{ margin: 16, height: "65vh" }}>
        <CustomTableComponents
          isMakingApiCall={isMakingApiCall}
          columns={columns.filter((column) => column.show)}
          dataArray={filteredData}
          handleDoubleClickRow={() => {}}
          handleSelectedRow={() => {}}
        />
        {isFilerDrawerOpen && (
          <FilteringDrawer
            closeFilteringDrawer={closeFilterDrawer}
            isFilteringDrawerOpened={isFilerDrawerOpen}
            resetFilter={fetchLogs}
            loading={false}
            formName={"Validator log filter"}
            title={t("validatorLogScreen.filterDrawer.validatorLogFilter")}
            onSave={() => onSaveFilter()}
            type={"Validator"}
            showSelect={true}
            selectLabel={t("validatorLogScreen.filterDrawer.validators")}
            selectValue={"serialNumber"}
            selectKey={"id"}
            data={validators?.data || []}
            optionValue={"serialNumber"}
            displayDateRange={false}
            /* dateRange={dateRange}
            setDateRange={setDateRange} */
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )}
        {isModalLocationOpen && (
        <ModalGoogleMaps
          kioskName={selectedLocation?.validatorSerialNumber}
          lat={selectedLocation?.latitude}
          lng={selectedLocation?.longitude}
          setIsMapModalOpened={handleCloseLocation}
        />
      )}
      </Card>
    </>
  );
};

export default ValidatorScans;
