import React from "react";
import { Form, Input, Select, Button, Divider } from "antd";
import { useForm } from "antd/es/form/Form";
import { countryPhoneCode } from "../../Utils/country-phone-codes";
import { useTranslation } from "react-i18next";

const validateMessages = {
  types: {
    email: "${label} is not a valid email",
  },
};

const UserForm = ({
  onSave,
  onSaveFailed,
  user,
  onCancel,
  onDirty,
  unsavedChanges,
  role,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const initialValues = {
    ...user,
  };

  return (
    <Form
      form={form}
      name="user"
      layout="vertical"
      initialValues={initialValues}
      validateMessages={validateMessages}
      onFinish={(values) =>
        onSave({
          ...values,
          roles: [role],
        })
      }
      onFinishFailed={onSaveFailed}
      autoComplete="off"
      onValuesChange={() => onDirty()}
    >
      {/*  <Form.Item
        label="Profile Picture"
        name="profilePicture"
        style={{ textAlign: "center" }}
      >
        <Spin spinning={uploading}>
          <UploadComponent
            fileList={fileList}
            setFileList={setFileList}
            handleUpload={handleUpload}
            uploading={uploading}
            setIsFileChosen={setIsFileChosen}
          />
        </Spin>
      </Form.Item> */}
      <Form.Item
        label={t("userScreen.form.name")}
        name="name"
        rules={[
          { required: true, message: t("userScreen.form.pleaseEnterName") },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("userScreen.form.surname")}
        name="surname"
        rules={[
          { required: true, message: t("userScreen.form.pleaseEnterSurname") },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("userScreen.form.email")}
        name="username"
        rules={[
          {
            required: true,
            message: t("userScreen.form.pleaseEnterEmail"),
          },
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: "Please enter a valid email address",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("userScreen.form.phoneCountryCode")}
        name="phoneCountryCode"
        defaultValue={user ? user.phoneCountryCode : ""}
        rules={[
          {
            required: true,
            message: t("userScreen.form.pleaseEnterPhoneCode"),
          },
        ]}
      >
        <Select
          size="medium"
          showArrow
          showSearch
          optionFilterProp="filterBy"
          allowClear
          optionLabelProp="label"
        >
          {countryPhoneCode.map((p) => (
            <Select.Option
              key={p.en}
              filterBy={`${p.en} +${p.phoneCode}`}
              value={p.phoneCode}
              label={
                <>
                  <span role="img">{p.emoji}</span>
                  {` +(${p.phoneCode})`}
                </>
              }
            >
              <span role="img">{p.emoji}</span>{" "}
              <span style={{ color: "gray" }}>{`+(${p.phoneCode})`}</span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={t("userScreen.form.phone")}
        name="phone"
        rules={[
          { required: true, message: t("userScreen.form.pleaseEnterPhone") },
        ]}
      >
        <Input type="number" maxLength={8} />
      </Form.Item>

      <Divider />
      <Form.Item style={{ float: "right" }}>
        <Button
          type="default"
          style={{ marginBottom: 32, marginRight: 8 }}
          onClick={() => onCancel(unsavedChanges)}
        >
          {t("drawerButtons.cancel")}
        </Button>
        <Button
          type="primary"
          style={{ marginBottom: 32 }}
          htmlType="submit"
          disabled={!unsavedChanges}
        >
          {user ? t("drawerButtons.update") : t("drawerButtons.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
