import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, message, Card, Input } from "antd";
import { style } from "../../Styles";
import { ReloadOutlined, MinusOutlined } from "@ant-design/icons";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBundleStatus,
  deleteBundle,
  getBundles,
} from "../../redux/bundleSlice";
import BundleDrawer from "./BundleDrawer";
import ActionButtonWithChangeStatus from "../CommonComponents/ActionButtonWithChangeStatus";
import { enumToUserFriendlyText } from "../../Utils/Imports/UserFriendlyEnum";
import Header from "../../global/Header/Header";
import { useTranslation } from "react-i18next";
import { getCardTypes } from "../../redux/cardTypeSlice";
import dayjs from "dayjs";

const Bundle = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("bundleScreen.table.name"),
      key: "name",
      render: (record) => {
        return record?.name ? <span>{record.name}</span> : <MinusOutlined />;
      },
      sorter: (a, b) => a.name.length - b.name.length,
      show: true,
    },
    {
      title: t("bundleScreen.table.price"),
      key: "price",
      render: (record) => {
        return record?.price ? (
          <span>{`€ ${record.price}`}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
      show: true,
    },
    {
      title: t("bundleScreen.table.numberOfTrips"),
      key: "numberOfTrips",
      render: (record) => {
        return record?.numberOfTrips ? (
          <span>{record.numberOfTrips}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
      show: true,
    },
    {
      title: t("bundleScreen.table.type"),
      key: "type",
      render: (record) => {
        return record?.type ? (
          <span>{enumToUserFriendlyText(record?.type)}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
      show: true,
    },
    {
      title: t("bundleScreen.table.bundleValidityStart"),
      key: "bundleValidityFrom",
      render: (record) => {
        return record?.bundleValidityFrom ? (
          dayjs(record?.bundleValidityFrom).isBefore(dayjs().startOf("day")) ?
          <span style={{...style.dateActif}} >{dayjs(record?.bundleValidityFrom).format("DD-MM-YYYY")}</span> :
          <span style={style.dateInactif} >{dayjs(record?.bundleValidityFrom).format("DD-MM-YYYY")}</span>
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
      show: true,
    },
    {
      title: t("bundleScreen.table.bundleValidityEnd"),
      key: "bundleValidityTo",
      render: (record) => {
        return record?.bundleValidityTo ? (
          dayjs(record?.bundleValidityTo).isAfter(dayjs().startOf("day")) ?
          <span style={style.dateActif}>{dayjs(record?.bundleValidityTo).format("DD-MM-YYYY")}</span> :
          <span style={style.dateInactif}>{dayjs(record?.bundleValidityTo).format("DD-MM-YYYY")}</span> 
        ) : (
          <MinusOutlined />
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
      show: true,
    },
    {
      title: t("bundleScreen.table.kioskType"),
      key: "type",
      render: (record) => {
        return record?.kioskType ? (
          <span>{enumToUserFriendlyText(record?.kioskType)}</span>
        ) : (
          t("bundleScreen.table.all")
        );
      },
      sorter: (a, b) => a.kioskType.length - b.kioskType.length,
      show: true,
    },
    {
      title: t("bundleScreen.table.status"),
      key: "active",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.active ? (
              <div style={style.statusActif}>ACTIVE</div>
            ) : (
              <div style={style.statusInactif}>INACTIVE</div>
            )}
          </span>
        );
      },
      sorter: (a, b) => a?.active - b?.active,
      show: true,
    },
    {
      title: t("bundleScreen.table.actions"),
      key: "actions",
      render: (text, record) => (
        <ActionButtonWithChangeStatus
          record={record}
          onDelete={() => handleDeleteBundle(record.id)}
          onRowClick={() => handleSelectedRow(record)}
          onChangeStatus={() => handleChangeStatus(record)}
        />
      ),

      show: true,
    },
  ];

  const [filter, setFilter] = useState("");
  const [bundle, setBundle] = useState(undefined);
  const [isMakingApiCall, setMakingApiCall] = useState(true);

  const dispatch = useDispatch();
  const { bundles } = useSelector((state) => state.bundles);
  const [isBundleDrawerOpen, setBundleDrawerOpen] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState([]);

  const fetchBundles = () => {
    dispatch(getBundles())
      .unwrap()
      .then(() => setMakingApiCall(false))
      .catch(() => setMakingApiCall(false));
  };

  useEffect(() => {
    fetchBundles();
  }, [dispatch]);

  const filtredData = bundles?.filter((item) => {
    return (
      item?.name?.toLowerCase()?.includes(filter.toLowerCase()) ||
      item?.price?.toString().toLowerCase().includes(filter.toLowerCase()) ||
      item?.bundleValidityFrom?.toString().includes(filter.toLowerCase()) ||
      item?.bundleValidityTo?.toString().includes(filter.toLowerCase()) ||
      item?.numberOfTrips?.toString().includes(filter.toLowerCase()) ||
      item?.kioskType?.toLowerCase().toString().includes(filter.toLowerCase()) ||
      item?.type?.toString().toLowerCase().includes(filter.toLowerCase())
    );
  });

  const handleSelectedRow = (record) => {
    setBundle(record);
    showDrawer();
  };
  const fetchCardType = () => {
    dispatch(getCardTypes())
      .unwrap()
      .then(() => {
        setMakingApiCall(false)
        setBundleDrawerOpen(true);
      })
      .catch(() => setMakingApiCall(false));
  }
  const showDrawer = () => {
    fetchCardType();
  };

  const onDrawerClose = () => {
    setBundleDrawerOpen(false);
    setBundle(undefined);
  };

  const handleDeleteBundle = (id) => {
    dispatch(deleteBundle(id))
      .unwrap()
      .then(() => {
        message.success(t("bundleScreen.messages.bundleDeletedSuccessfully"));
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const handleChangeStatus = (record) => {
    dispatch(changeBundleStatus({ id: record.id, active: !record.active }))
      .unwrap()
      .then(() => {
        message.success(
          t("bundleScreen.messages.bundleStatusUpdatedSuccessfully")
        );
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const handleGenerateCsv = (event, done) => {
    const promise = async () => {

      setCsvHeaders([
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Price",
          key: "price",
        },
        {
          label: "Number of Trips",
          key: "numberOfTrips",
        },
        {
          label: "Type",
          key: "type",
        },
        {
          label: "Kiosk Type",
          key: "type",
        },
        {
          label: "Status",
          key: "active",
        },
      ]);
    };

    promise().then(done());
  };

  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

  return (
    <>
      <Header
        addButtonText={t("bundleScreen.header.addBundle")}
        onAddButtonClick={() => showDrawer()}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("bundleScreen.header.search")}
        addButtonBackgroundColor={primaryColor} // #ecefff
        addButtonBorderColor={primaryColor}
        addButtonTextColor={"#ffffff"}
        onReload={fetchBundles}
        showAddButton={true}
        BreadcrumbItems={[
          {
            title: t("bundleScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={filtredData}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Bundle"}
      />
      <Card style={{ margin: "20px 0", height: "85vh" }}>
        <CustomTableComponents
          isMakingApiCall={isMakingApiCall}
          columns={columns.filter((column) => column.show)}
          dataArray={filtredData}
          handleDoubleClickRow={(record) => handleSelectedRow(record)}
          handleSelectedRow={() => { }}
        />
        {isBundleDrawerOpen && (
          <BundleDrawer onClose={onDrawerClose} bundleId={bundle?.id} />
        )}
      </Card>
    </>
  );
};

export default Bundle;
